@import "../../styles/variables";

.userBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #21211F;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 0;
  border-radius: 5px;
}
.userBtnLink {
  color: #fff !important;
  text-decoration: none !important;
}
