@import "../../styles/variables";

.swiper-button-next, .swiper-button-prev {
  color: #fff !important;
}
.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .swiper-pagination-fraction {
    font-size: 20px;
    color: #fff;
  }
  .swiper-pagination-fraction {
    font-size: 50px;
    color: #fff;
  }
}