@import "../../styles/variables";
.autoInputWhite {
  font: inherit;
  letter-spacing: inherit;
  color: #000;
  /* padding: 4px 0 5px; */
  border: 0;
  box-sizing: border-box;
  background: #fff;
  height: 56px;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  outline: none;
}
