@import "../../styles/variables";

.swiper-btn-prev,
.swiper-btn-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: transform 0.2s ease;
}

.swiper-btn-prev:hover,
.swiper-btn-next:hover {
  transform: translateY(-50%) scale(1.1);
}

.swiper-btn-prev {
  left: 20px;
}

.swiper-btn-next {
  right: 20px;
}

@media screen and (max-width: 480px) {
  .swiper-btn-prev,
  .swiper-btn-next {
    width: 30px;
  height: 30px;
  }

  .swiper-btn-prev {
    left: 10px;
  }
  
  .swiper-btn-next {
    right: 10px;
  }
}