@import "og-style";

// Syncfusion Styles
@import '@syncfusion/ej2-base/styles/bootstrap.css';
@import '@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import '@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '@syncfusion/ej2-lists/styles/bootstrap.css';
@import '@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '@syncfusion/ej2-popups/styles/bootstrap.css';
@import "@syncfusion/ej2-pdfviewer/styles/bootstrap.css";
@import "@syncfusion/ej2-react-pdfviewer/styles/bootstrap.css";
// @import "@syncfusion/ej2-react-input/styles/bootstrap.css";
@import '@syncfusion/ej2-calendars/styles/bootstrap.css';
@import '@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import '@syncfusion/ej2-react-grids/styles/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/roboto/roboto-v30-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/roboto/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/roboto/roboto-v30-latin-300.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/roboto/roboto-v30-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/roboto/roboto-v30-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/roboto/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/roboto/roboto-v30-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/roboto/roboto-v30-latin-italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/roboto/roboto-v30-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/roboto/roboto-v30-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/roboto/roboto-v30-latin-500.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/roboto/roboto-v30-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/roboto/roboto-v30-latin-700.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/roboto/roboto-v30-latin-900.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/roboto/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/roboto/roboto-v30-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-900.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/roboto/roboto-v30-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/roboto/roboto-v30-latin-900.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* praise-regular - latin */
@font-face {
  font-family: 'Praise';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/praise/praise-v5-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('fonts/praise/praise-v5-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/praise/praise-v5-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/praise/praise-v5-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('fonts/praise/praise-v5-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/praise/praise-v5-latin-regular.svg#Praise') format('svg');
  /* Legacy iOS */
}

html {
  // overflow-x: hidden;
  // padding-right: 16px !important;
  background-color: #000;
}
body {
  margin: 0;
  // padding-right: 16px !important;
  // overflow-x: hidden;
  font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  width: 100vw;
  background: #000;
}
.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  background-image: url('../public/logo192.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: stretch;
  animation-duration: 2.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;

  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}
.loader--hidden {
  display: none;
}

@keyframes stretch {
  0% {
    transform: scale(.5)
  }

  100% {
    transform: scale(2);
  }
}

// .price {
//   font-size: 30px;
//   line-height: 40px;
//   font-weight: 500;
// }
// .priceNoVat {
//   font-size: 15px;
//   line-height: 40px;
//   font-weight: 200;
//   display: block;
// }
// .old-price {
//   font-size: 15px;
//   text-decoration: line-through;
// }

.line-through {
  text-decoration: line-through;
}

a {
  text-decoration: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  transition: 1s opacity fade-in;
  opacity: 1;
}

img{
  height: revert-layer !important;
}

.text-contrast{
  color: #FFCE00;
}

.container-boxed{
  // width: 1200px;
  max-width: 1200px;
  padding: 0 20px;
}

.plus-icon .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
  transform: rotate(45deg) !important;
}

.MuiAccordionSummary-content{
  margin: 0 !important;
}

.MuiAccordion-gutters.Mui-expanded {
  margin: 0 !important;
}

.mobile-drawer div.MuiPaper-root{
  background-color: #262626 !important;
}

.blor-button{
  background-color: #ffce00;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 5px 30px;
  border-radius: 50px;
}
.blor-button:hover{
  background-color: #fff;
}

.blor-outline-button{
  border: 2px solid #ffce00;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 5px 30px;
  border-radius: 50px;
}
.blor-outline-button:hover{
  background-color: #ffce00;
  color: black
}

.blor-input{
  width: 100%;
  background-color: #000 !important;
  .MuiOutlinedInput-root{
    border-radius: 50px;
  }
  p{
    color: #fff;
    font-size: 14px;
  }
  label{
    // left: 20px;
    padding: 10px;
    padding-top: 0;
    font-size: 30px;
    line-height: 30px;
    max-width: initial !important;
    background-color: #000;
  }
  input{
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    z-index: 10
  }

  textarea{
    padding: 10px;
    color: #fff;
  }

  .MuiSelect-select{
    color: #fff;
    padding-left: 20px;
  }

}
.blor-checkbox{
  color: #fff !important;
  svg{
    width: 16px;
    height: 16px;
  }
  .MuiFormControlLabel-label {
  }
}

.form-container form{
  width: 100%;
}

.address-table{
  background-color: #000;
  border-radius: 20px 20px 0 0;
  th{
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-right: 4px solid #262626;
  }
  td{
    color: #fff;
    border: none;
  }
}

.blor-checkbox{
  span{
    // color: white;
  }
}

.floating-label {
  position: absolute;
  top: 50%; /* Posizionamento iniziale (centrato verticalmente) */
  left: 10px; /* Margine sinistro */
  transform: translateY(-50%);
  transition: all 0.2s ease; /* Transizione fluida */
  color: #888; /* Colore grigio per la label inattiva */
  pointer-events: none; /* La label non interferisce con i click */
  font-size: 16px;
}

.floating-label.focused {
  top: 4px; /* Sposta la label in alto */
  font-size: 24px; /* Riduce la dimensione */
  color: #FFCE00; /* Colore evidenziato */
}

.blor-input.autocomplete input:focus{
  outline: none;
}

.orders-table{
  // background-color: #000;
  // border-radius: 20px 20px 0 0;
  th{
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-top: 2px solid #FFCE00;
    border-bottom: 2px solid #FFCE00;
  }
  td{
    color: #fff;
    border-top: 3px solid #262626;
    border-bottom: 3px solid #262626;
  }
  tr:first-child td{
    color: #fff;
    border-top: none;
    border-bottom: 3px solid #262626;
  }
}

@media screen and (max-width: 480px) {
  .blor-input{
    width: 100%;
    .MuiOutlinedInput-root{
      border-radius: 50px;
    }
    label{
      font-size: 20px;
      line-height: 30px;
    }
  }
}