// space generation variables
$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100);
$weights: (100, 200, 300, 400, 500, 600, 700, 800, 900);
$sides: (top, bottom, left, right); // all positions

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .b#{str-slice($side, 0, 1)}-#{$space} {
      border-#{$side}: #{$space}px solid #ccc !important;
    }
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    @each $side2 in $sides {
      .b#{str-slice($side, 0, 1)}#{str-slice($side2, 0, 1)}-#{$space} {
        border-#{$side}-#{$side2}-radius: #{$space}px !important;
      }
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .fs-#{$space} {
    font-size: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  .w-#{$space} {
    width: #{$space}#{'%'} !important;
  }

  .h-#{$space} {
    height: #{$space}#{'%'} !important;
  }
}

@each $space in $spaceamounts {
  .wp-#{$space} {
    width: #{$space}#{'px'} !important;
  }

  .hp-#{$space} {
    height: #{$space}#{'px'} !important;
  }
}

@each $space in $spaceamounts {
  .b-#{$space} {
    border: #{$space}px solid var(--color-primary) !important;
  }
}

@each $space in $spaceamounts {
  .brd-#{$space} {
    border-radius: #{$space}px !important;
  }
}

.lh-1 {
  line-height: 1 !important;
}

@each $space in $spaceamounts {
  .lhp-#{$space} {
    line-height: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  .ls-#{$space} {
    letter-spacing: #{$space}px !important;
  }
}

@each $weight in $weights {
  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-linethrough {
  text-decoration: line-through;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexStartVertical {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flexStartEnd {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.flexStartCenter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexCenterHorizontal {
  display: flex;
  justify-content: center;
}
.flexCenterVertical {
  display: flex;
  align-items: center;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flexEndStart {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.flexEndStart {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.flexEndVertical {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.bg-primary {
  background-color: var(--ion-color-primary) !important;
}

.bg-secondary {
  background-color: var(--ion-color-secondary) !important;
}

.bg-tertiary {
  background-color: var(--ion-color-tertiary) !important;
}

.bg-medium {
  background-color: var(--ion-color-medium) !important;
}

.bg-light {
  background-color: var(--ion-color-light) !important;
}

.color-primary {
  color: #000 !important;
}
.color-primary-contrast {
  color: #e0e0e0 !important;
}

.color-secondary {
  color: #e0e0e0 !important;
}
.color-secondary-contrast {
  color: #000 !important;
}

.color-tertiary {
  color: var(--ion-color-tertiary) !important;
}
.color-tertiary-contrast {
  color: var(--ion-color-tertiary-contrast) !important;
}

.color-medium {
  color: var(--ion-color-medium) !important;
}
.color-medium-contrast {
  color: var(--ion-color-medium-contrast) !important;
}

.color-light {
  color: var(--ion-color-light) !important;
}
.color-light-contrast {
  color: var(--ion-color-light-contrast) !important;
}

.noPointerEvents {
  pointer-events: none;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}