@import "../../styles/variables";

.swiper-button-next::after, .swiper-button-prev::after {
  color: #d5a22a;
}
.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.container-slide {
  transition: all 0.3s ease-in-out;
}

/* Blur attivato solo quando si va in hover sul pulsante */
.acquista-button:hover ~ .container-slide {
  backdrop-filter: blur(100px);
  background: red !important;
}
